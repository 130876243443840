import React, { useEffect } from 'react'
import Layout from "../../share/layout";
import '.././global.css'
import '.././movil.css'
import '../../../styles/font-awesome-4.7.0/css/font-awesome.css'
import Image0 from '../../../images/sonidosaudiovisuales/face.png'
import Image1 from '../../../images/sonidosaudiovisuales/artista.png'
import Image2 from '../../../images/sonidosaudiovisuales/back.png'
import ImageLupa from '../../../images/sonidosaudiovisuales/lupa.png'
import ImageVered1 from '../../../images/sonidosaudiovisuales/imgvered1.jpg'
import ImageVered2 from '../../../images/sonidosaudiovisuales/imgvered2.jpg'
import Magnifier from "react-magnifier";
import audio from '../Calycanto.mp3'

const Emilio = () => {
    useEffect(() => {
        document.body.classList.add('body-sonidos-visuales');
        return function cleanup() {
            document.body.classList.remove('body-sonidos-visuales');
        };
    }, [])
    return (
        <Layout
            title="Geografías | UPC Cultural"
            metaTitle="Geografías | UPC Cultural"
            description="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos. "
            subtitile="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos."
            metaDescripcin="Exhibición multimedia que presenta seis propuestas de artistas peruanos contemporáneos."
            imgurl="//images.ctfassets.net/bv5rnjawitjg/E9aFHf11TxaEIX6JuU3vL/550f80daa53881d13f10dc95466806e2/800_x_800-1.png?h=300"
            keywords="Multimedia, Audiovisual, Luis Alvarado, Emilio Le Roux, Vered Engelhard, Luis Sanz, Paola Torres Núñez del Prado, Jonathan Castro Alejos, Lucía Beaumont."
        >
            <section id="section-content" class="section-content">
                <div class="region-inner region-content-inner">
                    <div class="face"><a href="https://www.facebook.com/upccultural1/" target="_blank"><img src={Image0} /></a></div>
                    <div id="page6" className="page page6 pdetalles">
                        <div className="inner">
                            <span className="bg1"></span>
                            <span className="bg2"></span>
                            <span className="bglogo2"><span className="logoimg"></span></span>
                            <div className="row">
                                <div className="col cleft">
                                    <div className="imagen">
                                        {/*<img id="zoom_05" src={ImageVered} data-zoom-image={ImageVered} />*/}
                                        <Magnifier src={ImageVered2} />
                                        <img src={ImageLupa} className="zoom" />
                                    </div>
                                    <div className="imagen">
                                        {/*<img id="zoom_05" src={ImageVered} data-zoom-image={ImageVered} />*/}
                                        <Magnifier src={ImageVered1} />
                                        <img src={ImageLupa} className="zoom" />
                                        <audio id="audio" autoPlay controls >
                                            <source src={audio} type="audio/mp3" />
                                            Your browser does not support the audio element.
                                        </audio>
                                    </div>
                                </div>
                                <div className="col cright">
                                    <div className="artista"><img src={Image1} /></div>
                                    <div className="titulo">
                                        <div className="nombre"><b>Vered Engelhard</b></div>
                                        <div className="obra"><b>Obra:</b> Cal y Canto</div>
                                    </div>
                                    <div className="detalle">
                                        <p>Cal y Canto (42'02'',2022)</p>
                                        <p>Cal y Canto es un mapa sonoro de mi cuerpo-territorio-agua. Es el registro de una performance duracional, precedida por un ejercicio de conexión con las distintas ancestralidades que me atraviesan (genéticas, afectivas, territoriales), a través de cantos impregnados en mi cuerpo. Cal y Canto fue grabada en Lima, Perú, en el baño de mi niñez, en un período de 3 días con una sesión por día, cada una con una duración de entre 25 y 45 minutos. El primer día llené la tina de agua, la cual permaneció ahí a lo largo del performance hasta el último día, cuando dejé al agua acumulada correr mientras tomaba una ducha.</p>
                                        <p>Cal y Canto fue grabado en dos canales: de agua y aire. Para el primero, bajo el agua, utilicé un hidrófono que permaneció en la tina. Para el segundo, en el aire, usé una grabadora convencional. Dentro de la tina, cantaba melodías que venían a mi cuerpo, a veces solo con mi voz y a veces amplificado a través de un pututu (waylla kepa o concha trompeta). El pututu me ayuda a mediar los mundos del aire y del agua, lo que me permite filtrar mi voz y cantar bajo el agua, transitando entre ambos elementos, y amplificándome. De esta forma lograba que mi voz coincida con la frecuencia resonante del baño, haciendo vibrar al agua desde el contacto del aire con la tierra.<br />
                                            Los cantos vienen de distintos lugares geográficos, desde rezos Judíos de Europa del Este hasta hualinas o canciones de agua de San Pedro de Casta en Huarochirí, nuestra cabecera de cuenca. Cabe recalcar que esto pasó a fines de enero, una semana después de que la petrolera española Repsol derrame 11,900 barriles de petróleo en Ventanilla, plagando Ancón y llegando hasta Chancay. Esta pieza carga también ese luto, de cantar mientras el mar, que es mi casa, estaba bañado en petróleo. En ese sentido, la considero como un rezo.</p>
                                        <p>La edición junta estos tres días en una sola toma, superponiéndolos mínimamente y tocando solo niveles. Se escuchan distintas lenguas, letras precisas como inventadas, olvidadas, recordadas y tergiversadas; de ese modo, representan, también, las distintas proximidades y distancias de mi cuerpo con los territorios a los cuales estos cantos corresponden. Entrando y saliendo se escucha una caminata sonora grabada en Ancón. Luego de editar la pieza sonora, la representé de forma visual en un mapa de mi cuerpo-territorio-agua.<br />(Vered Engelhard)</p>
                                        <p>Biografía:<br />
                                            Vered Engelhard es artista y académico peruane basade en Nueva York. Su trabajo se centra en las ecologías acústicas y la dimensión ética de los objetos. Utilizando instrumentos móviles como flautas, pututos, sonajas y móviles y amplificando elementos presentes como viento, agua y piedras; su música es siempre emergente del lugar de su acontecimiento. Sus composiciones han sido performadas en National Sawdust, Areté Gallery, the Segal Theatre, Columbia University, Human Impact Institute, entre otros. Sus escritos han sido publicados en Nueva York, Sao Paulo y Lima. Performa con el conjunto experimental Siestaaa y en su proyecto como solista Canto Villano.</p>
                                        <p><a href="https://www.vered-engelhard.com/" target="_blank">https://www.vered-engelhard.com/</a></p>
                                    </div>
                                    <a className="btnback" href='/galeria/geografias'>
                                        <a data-page="p0"><img src={Image2} /></a>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </Layout>
    )
}

export default Emilio